export const obtenerUSO_AS510B = async () => {
    try {
          const response = await fetch('/data/AS510B/infoUsoDeEquipo.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};

export const obtenerUSO_SC250 = async () => {
    try {
          const response = await fetch('/data/SC250/infoUsoDeEquipo.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};

export const obtenerUSO_AS710R = async () => {
    try {
          const response = await fetch('/data/AS710R/infoUsoDeEquipo.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};

export const obtenerUSO_ROS1300 = async () => {
    try {
          const response = await fetch('/data/ROS1300/infoUsoDeEquipo.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};
export const obtenerUSO_AS4325 = async () => {
    try {
          const response = await fetch('/data/AS4325/infoUsoDeEquipo.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};

export const obtenerUSO_AS1050R = async () => {
    try {
          const response = await fetch('/data/AS1050R/infoUsoDeEquipo.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};

export const obtenerUSO_SC351B = async () => {
    try {
          const response = await fetch('/data/SC351B/infoUsoDeEquipo.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};

export const obtenerUSO_SW750B = async () => {
    try {
          const response = await fetch('/data/SW750B/infoUsoDeEquipo.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};

export const obtenerUSO_SC6000 = async () => {
  try {
        const response = await fetch('/data/SC6000/infoUsoDeEquipo.json', {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
        const jsonData = await response.json();
        return jsonData;
      } catch (error) {
        console.error("Error fetching data from JSON:", error);
        return [];
      }
};

export const obtenerUSO_SC6500 = async () => {
  try {
        const response = await fetch('/data/SC6500/infoUsoDeEquipo.json', {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
        const jsonData = await response.json();
        return jsonData;
      } catch (error) {
        console.error("Error fetching data from JSON:", error);
        return [];
      }
};

export const obtenerUSO_SR1601LPG = async () => {
  try {
        const response = await fetch('/data/SR1601LPG/infoUsoDeEquipo.json', {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
        const jsonData = await response.json();
        return jsonData;
      } catch (error) {
        console.error("Error fetching data from JSON:", error);
        return [];
      }
};

export const obtenerUSO_SW5500B = async () => {
  try {
        const response = await fetch('/data/SW5500B/infoUsoDeEquipo.json', {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
        const jsonData = await response.json();
        return jsonData;
      } catch (error) {
        console.error("Error fetching data from JSON:", error);
        return [];
      }
};

export const obtenerUSO_SW8000 = async () => {
  try {
        const response = await fetch('/data/SW8000/infoUsoDeEquipo.json', {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
        const jsonData = await response.json();
        return jsonData;
      } catch (error) {
        console.error("Error fetching data from JSON:", error);
        return [];
      }
};

export const obtenerUSO_AS580R = async () => {
  try {
        const response = await fetch('/data/AS850R/infoUsoDeEquipo.json', {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
        const jsonData = await response.json();
        return jsonData;
      } catch (error) {
        console.error("Error fetching data from JSON:", error);
        return [];
      }
};