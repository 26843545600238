import { useEffect, useState } from "react"; 

import NavBar from '../components/NavBar/NavBar'
import Banner from '../Componentes/Banners/Banner';
import UsoDelEquipo5C from '../Componentes/UsoDelEquipo/UsoDelEquipo';
import Video from "../Componentes/Video/video";
import ManualesYDocumentos from '../Componentes/ManualesYDocumentos/ManualesYDocumentos2item.';
import RedesSociales from '../Componentes/RedesSociales/RedesSociales';
import Footer from '../Componentes/Footer/Footer'
import InfoPrincipal from '../Componentes/InfoPrincipal/InfoPrincipal';

import { obtenerDataBanner } from '../Componentes/infoJsons/infoBanner.js'
import { obtenerInfoPrin_SW550B } from '../Componentes/infoJsons/infoPrincipal.js'
import { obtenerUSO_SW5500B } from '../Componentes/infoJsons/infoUsoDelEquipo.js'

const SW5500B = () => {

      //Banner
      const [data, setData] = useState([]);
      //
    
      //inFo Principal
        const [infoPrinc, setInfoPrinc] = useState([]);
      //
    
      //UsoDelEquipo
        const [USO, setUSO] = useState([]);
      //
    
        useEffect(() => {
    
            //Banner
            obtenerDataBanner().then(myJson => {setData(myJson);});
    
            //INFOPRINCIPAL
            obtenerInfoPrin_SW550B().then(infoPrincipal => {setInfoPrinc(infoPrincipal);});
    
            //USODELEQUIPO
            obtenerUSO_SW5500B().then(uso => {setUSO(uso);});
            //
        }, []);

        const listas = [
        ]
        
        const listasDesc2 = [
        ]



        const videos = [
            "https://nilfiskchile.cl/assets/videos/B-SW5500B/SW5500 B_Guía de Uso_espannol.mov"
          ];
          
          const posters = [
            "https://nilfiskchile.cl/assets/videos/B-SW5500B/video-SW5500B-02.jpg"
          ];
          
          const thumbnails = [
            "https://nilfiskchile.cl/assets/videos/B-SW5500B/video-sW5500B-02.jpg"
          ];

          const elementosCarrusel = [
            {
              imgSrc: "https://nilfiskchile.cl/assets/imgSW5500B/Manuales/uso-y-mantencion.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/SW5500B/Manuales/SW5500 B_Uso_mantenimiento_español_Chile.pdf",
              label: 'Uso Y Mantencion',
            },
            {
              imgSrc: "https://nilfiskchile.cl/assets/imgSW5500B/Manuales/uso-y-mantencion.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/SW5500B/Manuales/SW5500 B_Catálogo_español_Chile.pdf",
              label: 'Folleto',
            }
          ];

    return (

        <div>
            <NavBar/>
            <Banner imageClass="BannerFregadora" titulo={data.length > 0 ? data[0].tituloBarredora : ""} />
            <InfoPrincipal data={infoPrinc} listas={listas} listasDesc2={listasDesc2}/>
            <UsoDelEquipo5C data={USO}/>
            <Video videos={videos} posters={posters} thumbnails={thumbnails}  />
            <ManualesYDocumentos elementosCarrusel={elementosCarrusel} />
            <RedesSociales />
            <Footer />
        </div>

    );
};

export default SW5500B;