import { useEffect, useState } from "react"; 

import NavBar from '../components/NavBar/NavBar'
import Banner from '../Componentes/Banners/Banner';
import UsoDelEquipo from '../Componentes/UsoDelEquipo/UsoDelEquipo';
import AlfinalizarElTrabajo5c from '../Componentes/UsoDelEquipo/UsoDelEquipo'; /* AQUI CAMBIAMOS PARA USAR DE MEJOR MANERA LA MAQUETA DE ESTA MAQUINA. */
import Video from "../Componentes/Video/video";
import ManualesYDocumentos from '../Componentes/ManualesYDocumentos/ManuealesYDocumentos';
import RedesSociales from '../Componentes/RedesSociales/RedesSociales';
import Footer from '../Componentes/Footer/Footer'
import InfoPrincipal from '../Componentes/InfoPrincipal/InfoPrincipal';

import { obtenerDataBanner } from '../Componentes/infoJsons/infoBanner.js'
import { obtenerInfoPrin_AS850R } from '../Componentes/infoJsons/infoPrincipal.js'
import { obtenerUSO_AS580R } from '../Componentes/infoJsons/infoUsoDelEquipo.js'
import { obtenerAlfinalizarAS850R } from "../Componentes/infoJsons/infoAlFinalizar.js";

const AS850 = () => {

      //Banner
      const [data, setData] = useState([]);
      //
    
      //inFo Principal
        const [infoPrinc, setInfoPrinc] = useState([]);
      //
    
      //UsoDelEquipo
        const [USO, setUSO] = useState([]);
      //
    
      //AL FINALIZAR EL TRABAJO
        const [final, setFinal] = useState([]);
      //
    
        useEffect(() => {
    
            //Banner
            obtenerDataBanner().then(myJson => {setData(myJson);});
    
            //INFOPRINCIPAL
            obtenerInfoPrin_AS850R().then(infoPrincipal => {setInfoPrinc(infoPrincipal);});
    
            //USODELEQUIPO
            obtenerUSO_AS580R().then(uso => {setUSO(uso);});
            //
    
            //AL FINALIZAR EL TRABAJO
            obtenerAlfinalizarAS850R().then(final => {setFinal(final);});
            //
        }, []);

        const listas = [
        ]
        
        const listasDesc2 = [
        ]



        const videos = [
            "https://nilfiskchile.cl/assets/videos/F-AS850R/AS850_Viper_video Uso y cuidado_esp.mov"
          ];
          
          const posters = [
            "https://nilfiskchile.cl/assets/videos/F-AS850R/"
          ];
          
          const thumbnails = [
            "https://nilfiskchile.cl/assets/videos/F-AS850R/"
          ];

          const elementosCarrusel = [
            {
              imgSrc: "https://nilfiskchile.cl/assets/img/AS850R/Manuales/catalogo.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/AS850R/Manuales/AS850 R_Catalogo_espanol.pdf",
              label: 'Catálogo',
            },
            {
              imgSrc: "https://nilfiskchile.cl/assets/img/AS850R/Manuales/uso-y-mantencion.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/AS850R/Manuales/AS850 R_Uso_mantencion_espanol_Chile.pdf",
              label: 'Uso Y Mantencion',
            },
            {
              imgSrc: "https://nilfiskchile.cl/assets/img/AS850R/Manuales/ficha-tecnica.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/AS850R/Manuales/AS850_Ficha_Tecnica_espanol.pdf",
              label: 'Ficha técnica',
            },
          ];

    return (

        <div>
            <NavBar/>
            <Banner imageClass="BannerFregadora" titulo={data.length > 0 ? data[0].tituloBarredora : ""} />
            <InfoPrincipal data={infoPrinc} listas={listas} listasDesc2={listasDesc2}/>
            <UsoDelEquipo data={USO}/>
            <AlfinalizarElTrabajo5c data={final} />
            <Video videos={videos} posters={posters} thumbnails={thumbnails}  />
            <ManualesYDocumentos elementosCarrusel={elementosCarrusel} />
            <RedesSociales />
            <Footer />
        </div>

    );
};

export default AS850;